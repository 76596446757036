import React from "react";
import { Helmet } from "react-helmet";
// import {Routes} from "./Routes";
// Screens
import { Landing } from "./screens/Landing.jsx";
import { Route, Routes } from "react-router-dom";
import { ContactUs } from "./screens/Contacts.jsx";

export default function App() {

  return (
    <>
    <Routes>
      <Route path="/" element={<Landing/>}/>
      <Route path="/contact" element={<ContactUs/>}/>
      <Route path="/home" element={<Landing/>}/>
    </Routes>

      {/* <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap" rel="stylesheet"/>
      </Helmet>
      <Routes /> */}
    </>
  );
}
