import React from "react";
import styled from "styled-components";
// import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Navbar, NavbarBrand } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
// Assets
// import CloseIcon from "../../assets/svg/CloseIcon";
// import LogoIcon from "../assets/svg/Logo";
import LogoIcon from "../../assets/images/sidebar-logo.png";
import { NavbarText } from "react-bootstrap";
//  import LogoIcon from "../../assets/images/logo-dark.png";
//import LogoIcon from "../../assets/images/favicon.ico";
import "../../assets/css/sidebar.css"
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';



const Sidebar = ({ sidebarOpen, toggleSidebar }) => {

    const activeStyle = {
      color: "red", // Set the desired color for the active state
    };

 

  return (
    <Wrapper className="animate darkBg " style={{ backgroundColor: "rgb(68, 67, 67)", paddingTop: "20px", paddingLeft: "0px", paddingRight: "0px", fontFamily: "Tilt Neon" }} sidebarOpen={sidebarOpen}>

      <SidebarHeader className="flexSpaceCenter element" id="header" style={{}} >
        <div className="flexNullCenter " style={{ paddingTop: "0px",paddingLeft:"20PX" }}>
          <div className="row">
          <div className="col-sm-4"></div>
          <div className="col-sm-4">
          <NavbarText ><p className="h2">Menu</p></NavbarText>
          </div>
          <div className="col-sm-4"></div>
          </div>
          {/* <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#">Navbar</Navbar.Brand>
        </Container>
      </Navbar> */}
          {/* <LogoIcon />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
            fanatic
          </h1> */}
          {/* { <img id="main-logo" src={LogoIcon} alt="" height={5} className="img-fluid" /> } */}
        </div>
        <div className="flexNullCenter " style={{ paddingTop: "0px",paddingRight:"20PX" }}>
        <button type="button" onClick={() => toggleSidebar(!sidebarOpen)} class="btn btn-secondary btn-circle btn-xl" style={{fontSize:"50", paddingRight:"10px",color:"white"}}>X</button> 
        </div>
        {/* <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="xbtn animate pointer">
        </CloseBtn> */}

      </SidebarHeader>
      <ListGroup as="ol" numbered >
        <hr className="style1"/>
        <Link to="/home" activeStyle={activeStyle}
              spy={true}
              smooth={true}
              offset={-80}>
        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
          <div className="ms-2 me-auto" style={{ paddingRight:"40"}} >
            <div className="fw-bold">Home</div>
          </div>
          <Badge bg="" className="arrow" >
                <span style={{fontSize:"18px"}}>></span>
          </Badge>
        </ListGroup.Item>
        </Link>
        <hr className="style1" />
        <Link className="navlink" 
              
              to="/contact"
              spy={true}
              smooth={true}
              offset={-80}>
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
          <div className="ms-2 me-auto">
            <div className="fw-bold">Contacts</div>

          </div>
          <Badge bg="" className="arrow" >
          <span style={{fontSize:"18px"}}>></span>
          </Badge>
        </ListGroup.Item>
        </Link>
        <hr className="style1" />
        {/* <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">Subheading3</div>
          Cras justo odio
        </div>
        <Badge bg="primary" pill>
          14
        </Badge>
      </ListGroup.Item> */}
      </ListGroup>
      {/* <ListGroup className="listGroup" style={{paddingLeft:"0px"}}>
        <hr/>
      <ListGroup.Item className="listgrp">Cras justo odio</ListGroup.Item>
      <hr/>
      <ListGroup.Item className="listgrp">Dapibus ac facilisis in</ListGroup.Item>
      <hr/>
      <ListGroup.Item className="listgrp">Morbi leo risus</ListGroup.Item>
      <hr/>
      <ListGroup.Item className="listgrp">Porta ac consectetur ac</ListGroup.Item>
      <hr/>
      <ListGroup.Item className="listgrp">Vestibulum at eros</ListGroup.Item>
      <hr/>
    </ListGroup> */}
      {/* <UlStyle className="flexNullCenter flexColumn" style={{width:"150px"}}> */}

      {/* <li className="semiBold font15 pointer">
          <NavLink
            // onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            // className="skyColor1"
            style={{ padding: "10px 150px" }}
            to="/"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Home
          </NavLink>
          <hr style={{color:"white", marginBottom:"50px"}}/>
          <hr></hr>

        </li>
        <li className="semiBold font15 pointer">
          <NavLink
            // onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="skyColor1"
            style={{ padding: "10px 15px" }}
            to="/contact"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Contact uss
            <hr></hr>
          </NavLink>
        </li>
        <Divider />
        <li className="semiBold font15 pointer">
         
         {/* <a href="https://hr.theonlineapps.com/newInsync/auth/signin-2" 
           style={{ padding: "10px 30px" }} className="radius8  lightBg">
           Sign in
         </a> 
       </li> */}
      {/* </UlStyle> */}

    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
display:block;
  box-sizing:border-box;
  width:20px;
  height:20px;
  border-width:3px;
  border-style: solid;
  border-color: #707070;
  border-radius:100%;
  background: -webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 50%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%);
  background-color: #707070;
  box-shadow:0px 0px 5px 2px rgba(0,0,0,0.5);
  transition: all 0.3s ease; 
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
export default Sidebar