import React from 'react'
import { Collapse } from 'react-collapse'

import {AiOutlineMinus, AiOutlinePlus} from "react-icons/ai"

const Accordionitem = ({open,toggle,title,desc}) => {
  return (
    <div className="pt-[10px]">
        <div className="bg-white py-[25px] px-[25px] flex justify-between items-center cursor-pointer"
        onClick={toggle} >
            <p className='text-[22px] font-Tilt-Neon'>{title}</p>
            <div className='text-[30px]'>
                {open ? <AiOutlineMinus/> : <AiOutlinePlus/>}
            </div>
        </div>
        <Collapse isOpened={open}>
            <div className="bg-white px-[50px] pd-[20px]">{desc}</div>
        </Collapse>
    </div>
  )
}

export default Accordionitem