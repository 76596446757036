import React from 'react'
import Accordion from './Accordion'

const FAQ = () => {

  const toTitleCase = (s) => {
    if (typeof (s) === 'string' && s.length > 0) {
      const words = s.split(' ')
      if (Array.isArray(words) && words.length > 0) {
        if (words.length === 1) {
          const word = words[0]
          const matches = word.charAt(0).match(/\w+/i)
          const lines = word.split('\n')
          if (Array.isArray(lines) && lines.length > 1) {
            return lines.map(line => {
              return toTitleCase(line)
            }).join('\n')
          } else if (Array.isArray(matches)) {
            return word.split('').map((c, i) => {
              if (i === 0) {
                return c.toUpperCase()
              }
              return c.toLowerCase()
            }).join('')
          } else {
            return word.charAt(0).concat(toTitleCase(word.slice(1)))
          }
        } else {
          return words.map(word => toTitleCase(word)).join(' ')
        }
      }
    }
    return ''
  }

  const openMaster = () => {
    window.open(
      "https://master.theonlineapps.com/auth/signin-2",
      "_blank"
    );
  };

  const openCRM = () => {
    window.open(
      "https://crm.theonlineapps.com/auth/signin-2?q=401",
      "_blank"
    );
  };

  const openHr = () => {
    window.open(
      "https://hr.theonlineapps.com/newInsync/auth/signin-2?q=103",
      "_blank"
    );
  };

  const openInventory = () => {
    window.open(
      "#",
      "_blank"
    );
  };

  const openPension = () => {
    ;
    window.open(
      "#",
      "_blank"
    );
  };

  const openHrm = () => {
    window.open(
      "https://accounts.theonlineapps.com/auth/signin-2?q=102",
      "_blank"
    );
  };
  return (
    <div className='p-0  rounded-lg'>
      <Accordion title='SUBSCRIPTION MANAGEMENT' details=
        <ul type="1" >
          <li type="disc" ><span>{toTitleCase("USER CREATION/MAINTENANCE")}</span></li>
          <li type="disc" ><span>{toTitleCase("COMPANY CREATION/MAINTENANCE ")}</span></li>
          <li type="disc" ><span>{toTitleCase("SYSTEMS SUBSCRIPTION")}</span></li>
          <li>
            <div className="p-1 btn mt-3 btn-primary items-right" onClick={() => openMaster()} style={{ background: '#429ef5', color: 'white' }}>Access</div>
          </li>
        </ul>>
      </Accordion>
      <Accordion title='HR & PAYROLL' details=
        <ul type="1" style={{ marginLeft: 10, marginTop: 0 }} >
          <li type="disc"><span>{toTitleCase("RECRUITMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("EMPLOYEE DETAILS MANAGEMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("LEAVE MANAGEMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("APPRAISAL")}</span></li>
          <li type="disc"><span>{toTitleCase("MONTHLY PAYROLL PROCESSING")}</span></li>
          <li type="disc"><span>{toTitleCase("EMPLOYEE LOANS MANAGEMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("RETIREMENT/EXIT")}</span></li>
          <li>
            <div className="p-1 btn mt-3 hover btn-primary items-right" onClick={() => openHr()} style={{ background: '#429ef5', color: 'white' }}>Access</div>
          </li>
        </ul>>
      </Accordion>
      <Accordion title='ACCOUNTS' details=
        <ul type="disc" >
          <li type="disc"><span>{toTitleCase("CHART OF ACCOUNTS")}</span></li>
          <li type="disc"><span>{toTitleCase("GENERAL LEDGER")}</span></li>
          <li type="disc"><span>{toTitleCase("SUBSIDIARY LEDGER")}</span></li>
          <li type="disc"><span>{toTitleCase("RECEIPTING")}</span></li>
          <li type="disc"><span>{toTitleCase("PAYMENTS")}</span></li>
          <li type="disc"><span>{toTitleCase("JOURNALS")}</span></li>
          <li type="disc"><span>{toTitleCase("BANK RECONCILIATION")}</span></li>
          <li>
            <div className="p-1 btn mt-3 hover btn-primary items-right" onClick={() => openHrm()} style={{ background: '#429ef5', color: 'white' }}>Access</div>
          </li>
        </ul>>
      </Accordion>
      <Accordion title='CRM' details=
        <ul type="1" >
          <li type="disc" ><span>{toTitleCase("Customer Contacts Management")}</span></li>
          <li type="disc" ><span>{toTitleCase("Quotation Management ")}</span></li>
          <li type="disc" ><span>{toTitleCase("Orders Processing")}</span></li>
          <li type="disc" ><span>{toTitleCase("Invoicing")}</span></li>
          <li type="disc" ><span>{toTitleCase("Customer Issues Tracking")}</span></li>
          <li type="disc" ><span>{toTitleCase("Sales Data Analytics")}</span></li>
          <li>
            <div className="p-1 btn mt-3 btn-primary items-right" onClick={() => openCRM()} style={{ background: '#429ef5', color: 'white' }}>Access</div>
          </li>
        </ul>>
      </Accordion>
      <Accordion title='PENSIONS FUND ADMIN' details=
        <ol type="disc" >
          <li type="disc" ><span>{toTitleCase("SCHEME DETAILS MANAGEMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("MEMBER DETAILS MANAGEMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("MEMBER CONTRIBUTIONS MANAGEMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("INTEREST PROCESSING")}</span></li>
          <li type="disc"><span>{toTitleCase("LEVIES & SCHEME EXPENSE MANAGEMENT")}</span></li>
          <li type="disc" ><span>{toTitleCase("CLAIMS MANAGEMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("AGENT COMMISSION PROCESSING")}</span></li>
          <li type="disc"><span>{toTitleCase("INTEGRATION TO ACCOUNTS")}</span></li>
          <li type="disc"><span>{toTitleCase("ANNUITIES MANAGEMENT")}</span></li>
          <li type="disc"><span>{toTitleCase("MEMBER ONLINE PORTAL")}</span></li>
          <l>
            <div className="p-1 btn mt-3 hover btn-primary items-right" onClick={() => openPension()} style={{ background: '#429ef5', color: 'white' }}>Access</div>
          </l>
        </ol>>
      </Accordion>
      <Accordion title='FIXED ASSETS' details=
        <ol type="disc" >
          <li type="disc" ><span>{toTitleCase("CUSTOMER & SUPPLIER DATABASE")}</span></li>
          <li type="disc"><span>{toTitleCase("ASSET CATEGORIZATION & CREATION")}</span></li>
          <li type="disc"><span>{toTitleCase("ASSET PURCHASES")}</span></li>
          <li type="disc"><span>{toTitleCase("DEPRECIATION")}</span></li>
          <li type="disc"><span>{toTitleCase("ASSET DISPOSAL")}</span></li>
          <li>
            <div className="p-1 btn mt-3 hover btn-primary items-right" onClick={() => openInventory()} style={{ background: '#429ef5', color: 'white' }}>Access</div>
          </li>
        </ol>>
      </Accordion>
    </div>
  )
}

export default FAQ