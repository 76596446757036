import React from "react"; 
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../Sections/Header";
import Footer from "../Sections/Footer";
import { Card, Col } from "react-bootstrap";
import Banner from "../../src/assets/images/banner-kuscco.png"
// import Banner1 from "../../src/assets/images/img-auth-bigz.png"
import Banner1 from "../../src/assets/images/sync-banner.png"
import { FaMap, FaEnvelope, FaPhone, FaMailBulk } from "react-icons/fa";

export const ContactUs = () => { 
return ( 
    <div style={{ backgroundColor: "#f8f9fb" }}>
    <link rel="preconnect" href="https://fonts.googleapis.com"/>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
    <link href="https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap" rel="stylesheet"/>
	<TopNavbar />
    <div
      className="container"
      style={{ minHeight: "695px", paddingTop: "125px", width: "100%", fontFamily:'Tilt Neon'}}
    >
        <div className="row align-items-start font-display">
        <div className="col-md-4"  ></div>
                <div className="col-md-4" style={{display:"flex", justifyContent:"center", justifyItems:"center",alignItems:"center"}} >
            <img className="w-155 h-97 p-5px banner-window" style={{height:97, width:155}} src={Banner1} alt=".."/>
            </div>
            <div className="col-md-4"></div>
            </div>
            <div className="row align-items-start font-display">
                <div className="col-md-4"></div>
                <div className="col-md-4" style={{textAlign:"center", fontSize:40}}>
                    Contacts
            </div>
            <div className="col-md-4"></div>
            </div>
            <div className="row align-items-start font-display" style={{whiteSpace:"break-spaces"}} >
            <div className="col-md-1"></div>
            <div className="col-md-3">
            <Card className="border-0" style={{ zIndex: 4 }}>
            <Card.Header>
              <Card.Title>
              <FaMap/>
              </Card.Title>
            </Card.Header>
            <Card.Body>
            Shelter-Afrique House 2nd Floor<br/> 
            North Wing Mamlaka Rd​<br/>
            Nairobi Kenya<br/> 
            ​​​​​+254 (020) 2133267
            feedback@synchrony.co.ke<br/>
            <a href="https://www.synchrony.co.ke">www.synchrony.co.ke</a>
              </Card.Body>
            </Card>
            </div>
            <div className="col-md-3">
            <Card className="border-0" style={{ zIndex: 4 }}>
            <Card.Header>
              <Card.Title>
                <FaEnvelope/>
              </Card.Title>
            </Card.Header>
            <Card.Body>  
            feedback@synchrony.co.ke
            absalom@synchrony.co.ke
              </Card.Body>
            </Card>
            </div>
            <div className="col-md-3">
            <Card className="border-0" style={{ zIndex: 4 }}>
            <Card.Header>
              <Card.Title>
                <FaPhone/>
              </Card.Title>
            </Card.Header>
            <Card.Body> 
                Call 
            +254 (020) 2133267
              </Card.Body>
            </Card>
            </div>
            <div className="col-md-2"></div>
        </div>
    </div>
    <Footer />
    </div>
); 
}; 

// export default ContactUs; 
