import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../Sections/Header";
// import Services from "./Sections/Services";
// import Projects from "./Sections/Projects";
// import Blog from "./Sections/Blog";
// import Pricing from "./Sections/Pricing";
// import Contact from "./Sections/Contact";
import Footer from "../Sections/Footer";

export const Landing = () => {

  return (
    <div style={{ backgroundColor: "#f8f9fb" }}>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Tilt+Neon&display=swap" rel="stylesheet"/>
      {/* <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link
        href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
        rel="stylesheet"
      /> */}
      <TopNavbar />
      <Header />
      {/* <Services />
      <Projects />
      <Blog />
      <Pricing />
      <Contact /> */}
      <Footer />
    </div>
  );
};

export default Landing;
