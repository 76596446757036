import First from "../assets/First.jpg";
import Second from "../assets/Second.jpg";
import Third from "../assets/Third.jpg";

const content = [
  {
    title: "First Slide",
    description: "This is the first slider Image",
    urls: First,
  },
  {
    title: "Second Slide",
    description: "This is the second slider Image",
    urls: Second,
  },
  {
    title: "Third Slide",
    description: "This is the Third slider Image",
    urls: Third,
  },
  {
    title: "Fourth Slide",
    description: "This is the Fourth slider Image",
    urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(133).jpg",
  },
  {
    title: "Fifth Slide",
    description: "This is the Fifth slider Image",
    urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(147).jpg",
  },
  {
    title: "Sixth Slide",
    description: "This is the Sixth slider Image",
    urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(105).jpg",
  },
  {
    title: "Seventh Slide",
    description: "This is the Seventh slider Image",
    urls: "https://mdbootstrap.com/img/Photos/Slides/img%20(102).jpg",
  },
];
export default content
